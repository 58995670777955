import { useQuery } from "@tanstack/react-query";
import { getUrl } from "aws-amplify/storage";

export type S3Object = {
  bucket?: string;
  key?: string;
  region?: string;
};

export const useS3Object = ({ key }: S3Object) => {
  return useQuery(
    ["getAsset", key],
    async () => {
      if (key === undefined || key === "") return null;
      const result = await getUrl({
        key,
      });
      return result.url.toString();
    },
    {
      staleTime: 750000,
      cacheTime: 800000,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};
