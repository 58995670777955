"use client";

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import { LoadingSpinner } from "@/components/assets_old/LoadingSpinner";
import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm";
import ResetPasswordForm from "@/components/auth/ResetPasswordForm";
import SignInForm from "@/components/auth/SignInForm";
import SignUpForm from "@/components/auth/SignUpForm";
import VerifyEmailForm from "@/components/auth/VerifyEmailForm";
import { Button } from "@/components/input/Button";
import ErrorBox from "@/components/input/ErrorBox";
import { chevronRight } from "@/data/EventIcons";
import { useS3Object } from "@/services/Amplify/hooks/getS3Object";
import { analytics } from "@/services/Analytics";
import { useAuthStore } from "@/services/Cognito/authStore";
import { trpc, type RouterOutput } from "@/services/Trpc/client";
import { useOnClickOutside } from "usehooks-ts";
import { create } from "zustand";

import AboutPageContent from "../about/AboutPageContent";

type Screen =
  | "About"
  | "Customize"
  | "SignIn"
  | "CreateAccount"
  | "Reset Password"
  | "Verify Email";

type CardTemplate = RouterOutput["cardTemplate"]["list"]["items"][number];
type HomePageModalContext = {
  visible: boolean;
  cardTemplate: CardTemplate | null;
  screen: Screen;
  setTemplate: (template: CardTemplate | null) => void;
  show: () => void;
  hide: () => void;
  setScreen: (screen: Screen) => void;
};

export const useHomePageModalContext = create<HomePageModalContext>((set) => ({
  visible: false,
  cardTemplate: null,
  screen: "About",
  setTemplate: (cardTemplate) => set({ cardTemplate }),
  show: () => {
    // make sure this doesn't somehow overlap the auth modal
    useAuthStore.getState().hideAuthenticator();
    set({ visible: true, screen: "Customize" });
  },
  hide: () => {
    set({ visible: false });
  },
  setScreen: (screen) => {
    set({ screen });
  },
}));

const CustomizeScreen = ({ template }: { template: CardTemplate }) => {
  const authenticated = useAuthStore((ctx) => ctx.status) === "authenticated";
  const setScreen = useHomePageModalContext((ctx) => ctx.setScreen);
  const setTemplate = useHomePageModalContext((ctx) => ctx.setTemplate);
  const router = useRouter();
  const { data: thumbnailURL } = useS3Object({
    key: template?.thumbnailURL || "",
  });
  const hide = useHomePageModalContext((ctx) => ctx.hide);

  const { mutate, error, isLoading, isError, isSuccess } =
    trpc.card.create.useMutation({
      onMutate: () => {
        router.prefetch("../editor");
      },
      onSuccess: (data) => {
        analytics
          .track("customizeCard", {
            templateId: template.id,
            templateTags: template.tags,
            templateName: template.name,
          })
          .catch(console.error);
        setTemplate(null);
        hide();
        router.push(`../editor?id=${data.id}`);
      },
      onError: () => setShowError(true),
    });
  const [showError, setShowError] = useState(false);

  function handleCreate() {
    if (!authenticated) {
      return setScreen("SignIn");
    }
    if (isLoading) return;
    return mutate({ templateId: template.id });
  }

  const showLoadingSpinner = isLoading || isSuccess;

  return (
    <div className="max-w-xl overflow-hidden rounded-xl">
      <div className="border-b border-slate-300 bg-white p-5">
        <div className="flex justify-between">
          <span className="ml-1  mt-1 text-lg font-bold">
            Do you want to customize {template.name}?
          </span>
          <div className="mt-1 pl-1">
            <button
              className="ml-auto h-7 w-7 place-self-center rounded-full bg-[#E8E8E8]"
              onClick={hide}
            >
              <span className="text-xl font-semibold text-[#979797]">×</span>
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white p-5">
        <div className="flex justify-center">
          {thumbnailURL && (
            <img
              src={thumbnailURL}
              className="aspect-9/16 mr-2 max-w-[150px] bg-gray-200 object-cover md:max-w-[200px] xl:max-w-[250px]"
            />
          )}
          <div className="flex flex-col justify-start text-left">
            <span>Customize and send this card to your friends or family!</span>
            {template.description && (
              <>
                <span className="pt-2 font-medium">Description:</span>
                <span>{template.description}</span>
              </>
            )}
          </div>
        </div>
        <div className="pt-3">
          <Button
            type="button"
            variant={"purple"}
            className="w-full"
            onClick={handleCreate}
          >
            {!showLoadingSpinner ? (
              "Start Customizing!"
            ) : (
              <div className="flex items-center justify-center">
                <span className="px-2">Loading...</span>
                <LoadingSpinner className="h-5 w-5" />
              </div>
            )}
          </Button>
        </div>
        {isError && showError && (
          <div className="mt-3 overflow-hidden rounded-lg">
            <ErrorBox
              message={`Sorry, we were unable to process your request. ${error.message}`}
              hide={() => setShowError(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const SignInScreen = () => {
  const setScreen = useHomePageModalContext((ctx) => ctx.setScreen);
  const handleCreate = () => setScreen("CreateAccount");
  const handleForgot = () => setScreen("Reset Password");

  return (
    <SignInForm
      header={
        <>
          <h3 className="text-lg font-semibold">
            Oops, you need to sign in to send a card!
          </h3>
          <hr className="mb-4 mt-2 border-gray-900"></hr>
          <Button onClick={handleCreate}>Create an Account</Button>
          <h3 className="mt-2 text-lg font-semibold">
            Already have an account? Sign in below.
          </h3>
          <hr className="my-2 mb-4 border-gray-900"></hr>
        </>
      }
      footer={
        <a
          className="flex cursor-pointer justify-center text-sm  font-bold text-[#6852c0] hover:text-[#7862d0]"
          onClick={handleForgot}
        >
          Forgot your Password?
        </a>
      }
    />
  );
};

const CreateAccountScreen = () => {
  const setScreen = useHomePageModalContext((ctx) => ctx.setScreen);
  const navigateToSignIn = () => setScreen("SignIn");
  const navigateToVerifyEmail = () => setScreen("Verify Email");
  return (
    <SignUpForm
      header={
        <>
          <h3 className="text-lg font-semibold">Create a FREE Account</h3>
          <hr className="my-4 border-gray-900"></hr>
        </>
      }
      navigateToSignIn={navigateToSignIn}
      navigateToVerifyEmail={navigateToVerifyEmail}
    />
  );
};

const ForgotPasswordScreen = () => {
  const email = useAuthStore((ctx) => ctx.signUpEmail);
  const setScreen = useHomePageModalContext((ctx) => ctx.setScreen);
  const navigateToSignIn = () => setScreen("SignIn");

  if (!email) return <ForgotPasswordForm navigateToSignIn={navigateToSignIn} />;
  else
    return (
      <ResetPasswordForm email={email} navigateToSignIn={navigateToSignIn} />
    );
};

const VerifyEmailScreen = () => {
  const email = useAuthStore((ctx) => ctx.signUpEmail);
  const setScreen = useHomePageModalContext((ctx) => ctx.setScreen);
  const navigateToCustomize = () => setScreen("Customize");
  if (!email) {
    setScreen("SignIn");
    return null;
  }
  return <VerifyEmailForm email={email} onSuccess={navigateToCustomize} />;
};

const AboutScreen = () => {
  const hide = useHomePageModalContext((ctx) => ctx.hide);
  const scrollRef = useRef<HTMLDivElement>(null);

  function handleScroll(): void {
    scrollRef.current ? (scrollRef.current.scrollTop += 300) : {};
  }

  return (
    <div className=" overflow-hidden rounded-xl border-2 shadow-lg shadow-gray-900">
      <div className="relative">
        <button
          className="absolute right-16 top-12 z-10 flex h-7 w-7 items-center justify-center rounded-full bg-[#E8E8E8] shadow-lg "
          onClick={hide}
        >
          <span className="text-xl font-semibold text-[#979797]">×</span>
        </button>
        <div
          ref={scrollRef}
          className="relative max-h-[85vh] overflow-y-scroll scroll-smooth"
        >
          <AboutPageContent />
        </div>
      </div>
      <div
        onClick={handleScroll}
        className="absolute bottom-20 right-1/2 rotate-90 rounded-full border-2 "
      >
        {chevronRight()}
      </div>
    </div>
  );
};

const ScreenManager = () => {
  const screen = useHomePageModalContext((ctx) => ctx.screen);
  const template = useHomePageModalContext((ctx) => ctx.cardTemplate);
  const authenticated = useAuthStore((ctx) => ctx.status) === "authenticated";

  if (authenticated && template) return <CustomizeScreen template={template} />;
  switch (screen) {
    case "About":
      return null;
    // return <AboutScreen />;
    case "Customize":
      if (template) return <CustomizeScreen template={template} />;
    case "SignIn":
      return <SignInScreen />;
    case "CreateAccount":
      return <CreateAccountScreen />;
    case "Reset Password":
      return <ForgotPasswordScreen />;
    case "Verify Email":
      return <VerifyEmailScreen />;
  }
};

const HomePageModal = () => {
  const visible = useHomePageModalContext((ctx) => ctx.visible);
  const hide = useHomePageModalContext((ctx) => ctx.hide);
  const authStatus = useAuthStore((ctx) => ctx.status);
  const screen = useHomePageModalContext((ctx) => ctx.screen);
  const setTemplate = useHomePageModalContext((ctx) => ctx.setTemplate);

  const ref = useRef(null);
  const handleClickOutside = () => {
    if (screen !== "About") hide();
  };
  useOnClickOutside(ref, handleClickOutside);

  useEffect(() => {
    // prevent scrollings when modal is open

    if (screen === "About" && authStatus === "authenticated") hide();
    if (visible) document.body.style.overflow = "hidden";

    return function () {
      document.body.style.overflow = "unset";
    };
  }, [authStatus, hide, screen, setTemplate, visible]);

  if (!visible || screen === "About") return null;

  return (
    <div
      className="relative"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ zIndex: 50 }}
    >
      <div className="fixed inset-0 h-full bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center ">
          <div ref={ref}>
            <ScreenManager />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageModal;
